import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from "react-router-dom";
import {withRouter} from 'react-router';
import About from "./about/About"
import Shop from "./shop/Shop"
import Gallery from "./gallery/Gallery"
import Home from "./home/Home"
import Instagram from "./assets/glyph-logo_May2016.png"

function App() {
  return (
    <div className="App">
        <Router>
            <header>
                <h3 className="title">Naomi Goddard</h3>
                    <nav className="nav">
                      <NavLink exact activeClassName="active" to="/">HOME</NavLink>
                      <NavLink activeClassName="active" to="/gallery">GALLERY</NavLink>
                      <NavLink activeClassName="active" to="/shop">SHOP</NavLink>
                      <NavLink activeClassName="active" to="/about">ABOUT</NavLink>
                    </nav>
                    <hr class="hr"/>
            </header>
            <Switch>
                <Route path="/shop">
                  <Shop />
                </Route>
                <Route path="/about">
                  <About />
                </Route>
                <Route path="/gallery">
                  <Gallery />
                </Route>
                <Route path="/">
                  <Home />
                </Route>
          </Switch>
        </Router>
        <footer>
            <hr/>
            <h4>FOLLOW ON THE SOCIALS</h4>
            <a href="https://www.instagram.com/goddardarts/" target="_blank"><img src={Instagram} className="social-logo"/></a>
        </footer>
    </div>
  );
}

export default App;
