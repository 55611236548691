import React from 'react'
import './Shop.css'
function Shop() {
    return (
        <div class="shop">
            <p>There are no items for sale at the moment. <br/>
            Please check back at a later date or follow Naomi on Instagram <a href="https://www.instagram.com/goddardarts/">@goddardarts</a>&nbsp;
        to stay up to date!</p>
        </div>
    )
}
export default Shop
