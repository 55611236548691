import React from 'react'
import "./About.css"
import Amsterdam from "../assets/amsterdam.png"
function About() {
    return (
        <div className="about">
            <div class="about-info">
                <div className="image-container">
                    <img className="about-img" src={Amsterdam}/>
                    <p>Outside of the Rembrant Museum in Amsterdam, Netherlands</p>
                </div>
                <p>A Computer Science student and frontend developer by day, her free time is spent letting out her artistic side through creating her pieces. Naomi is a hobby artist with work predominantly done with oil paint or graphite.
                    Her work consists of portraits, animals, and sci-fi/fantasy illustrations.
                    Her illustrative art is influenced by cinema and video game concept art.
                    Her realistic pieces are influenced by classic artists such as Vincent Van Gogh and John Singer Sargent. In 2019, she was able to visit the Van Gogh Museum in Amsterdam, Netherlands to experience one of her favorite artists in person.
                    She currently resides in Florida with her two huskies.
                </p>
            </div>
        </div>
    )
}
export default About
