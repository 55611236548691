import React, {Component} from 'react'
import "./Home.css"
class Home extends Component {
    constructor() {
        super();
        this.state = {
            images: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        }
    }

    render() {
        return (
            <div className="grid">
                {this.state.images.map((img, i) => (
                    <div>
                        <div key={i} className="block">
                        </div>
                    </div>

                ))}
            </div>
        )
    }
}
export default Home
